<template>
  <v-row
    class="flex-wrap"
    v-if="fields.length"
  >
    <v-col
      v-for="(customField, index) in fields"
      :key="customField.id"
      :cols="cols"
    >
      <!-- Text fields -->
      <long-text-field
        v-if="!customField.isDateType && !customField.isEnumType && !customField.isNumberType"
        clearable
        :disabled="disabled"
        :label="customField.name"
        :required="customField.isRequired"
        :rules="rules(customField)"
        :text.sync="customField.value"
        :counter="maxTextLength"
        @update:text="propagateChange"
      />

      <!-- Number fields -->
      <v-text-field
        type="number"
        v-if="customField.isNumberType"
        clearable
        :disabled="disabled"
        :label="customField.name"
        :required="customField.isRequired"
        :rules="rules(customField)"
        v-model="customField.value"
        @input="propagateChange"
      />

      <!-- Preset fields -->
      <div
        ref="enum"
        class="wrapper"
      >
        <v-autocomplete
          v-if="customField.isEnumType"
          :label="customField.name"
          item-text='value'
          item-value='value'
          :items="customField.enumValues"
          :required="customField.isRequired"
          :disabled="disabled"
          :rules="rules(customField)"
          v-model="customField.value"
          @change="propagateChange"
          @click="checkOutOfView(index)"
          :menu-props="{eager: true, top: isOutOfView[index], contentClass: 'background-plain'}"
          :attach="$refs.enum && $refs.enum[index]"
        />
      </div>

      <!-- Date fields -->
      <date-picker-menu
        v-if="customField.isDateType"
        :label="customField.name"
        v-model="customField.value"
        @input="propagateChange"
        :rules="rules(customField)"
        :required="customField.isRequired"
        :clearable="!customField.isRequired"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    LongTextField: () => import('@/components/long-text-field')
  },
  beforeDestroy () {
    Object.values(this.observers).map(o => o.disconnect())
  },
  data () {
    return {
      maxTextLength: 255,
      isOutOfView: {},
      observers: {}
    }
  },
  computed: {
    fields: {
      get () {
        return this.lodash
          .cloneDeep(this.customFields)
          .sort((a, b) => a.sequence - b.sequence)
          .map(customField => {
            if (customField.enumValues?.length) {
              customField.enumValues.sort((a, b) => a.value.localeCompare(b.value))
              if (customField.value === null) {
                customField.value = (
                  customField.enumValues.some(value => value.isDefault)
                    ? customField.enumValues.filter(value => value.isDefault)
                    : customField.enumValues
                )[0].value
              }
            }
            return customField
          })
      },
      set (fields) {
        this.propagateChange()
      }
    }
  },
  methods: {
    propagateChange () {
      this.$emit('update:custom-fields', this.fields)
    },
    checkOutOfView (index) {
      const element = this.$refs.enum && this.$refs.enum[index]
      if (!element) {
        this.$set(this.isOutOfView, index, false)
        return
      }
      const menu = element.querySelector('div.v-menu__content')
      if (!menu) {
        this.$set(this.isOutOfView, index, false)
        return
      }
      if (!this.observers[index]) {
        this.observers[index] = new ResizeObserver(() => {
          this.$set(this.isOutOfView, index, (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < menu.getBoundingClientRect().bottom)
        })
        this.observers[index].observe(menu)
      }
    },
    rules (customField) {
      const rules = []
      if (!customField.isDateType && !customField.isEnumType && !customField.isNumberType) {
        rules.push(v => (v || '').length <= this.maxTextLength)
      }
      if (customField.isNumberType) {
        rules.push(v => !isNaN(v))
      }
      if (customField.isRequired) {
        if (customField.isNumberType) {
          rules.push(v => !isNaN(parseFloat(v)) || this.$t('t.IsRequired'))
        } else {
          rules.push(v => Boolean(v && v.trim()) || this.$t('t.IsRequired'))
        }
      }
      return rules
    },
    updateDate (customfield) {
      this.$refs[customfield.id + 'menu'][0].save(customfield.value)
      this.propagateChange(customfield.value)
    }
  },
  props: {
    cols: {
      type: Number,
      default: 3
    },
    customFields: Array,
    disabled: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative
</style>
